import React from 'react'
import banner from '../../Images/banner.jpg';


function PscComponent() {
    return (
        <div>
            <div className='row mb-5'>
                <div className='col mt-5'>
                    <img src={banner} className='img-fluid banner_class' alt='Banner' />
                </div>
            </div>

            <div className='row text-center'>
                <div className='col '>
                    <p className='class_ielt'> Best PSC Coaching Center</p>
                    <p className='class_para'>
                        Adens Academy  is your one stop solution to land in your Dream Jobs in the government sector. We provide holistic training sessions to every candidate enrolled with us and make sure each one comes out in flying colours in PSC exams.
                    </p>
                </div>
            </div>

            <div className='row text-center'>
                <div className='col '>
                    <p className='class_ielt'>Courses we offer for you     </p>
                    <p className='class_para'>
                        We offer you the Best coaching classes for Kerala PSC, SSC, UPSC, SBI/IBPS,RRB,JEE/NEET Exams from the best trainers.
                    </p>
                </div>
            </div>


            <div className='row text-center'>
                <div className='col '>
                    <p className='class_ielt'> Bank Exams  </p>

                    <p className='class_para'>
                        Most candidates in these era are looking for a Secure and Growing Career option in India, and eventually they will land in banking sector. We all know that after completing Graduation IBPS / SBI PO Exam is the best ever option for a secure Bank job, well if you have an aim to crack IBPS / SBI PO Exams then you may be looking for the best Bank coaching institute in Kerala.

                        Wow! Finally you landed over the perfect choice – Talent Academy.

                        After graduation million of aspirants are giving Bank Exams like IBPS exam, SBI PO exam every year and competition in the exam are getting higher day by day. In these atmosphere we will make you different and topper among the equals.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PscComponent