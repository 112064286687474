import React from 'react'
import banner from '../../Images/banner.jpg';

function TuitionComponent() {
  return (
    <div>
      <div className='row mb-5'>
        <div className='col mt-5'>
          <img src={banner} className='img-fluid banner_class' alt='Banner' />
        </div>
      </div>

      <div className='d-flex justify-content-center'>
        <div className='row text-center'>
          <div className='col  '>
            <p className='class_ielt'>Tuition And Academic Support</p>
            <p className='class_para text-start'>
              <li>English And Malayalam Medium Available</li>
              <li>Online And Direct Batch is Available</li>
              <li>Class VIII- +2 For All Subjects</li>
              <li>Monitoring And HomeWork</li>
              <li>And DaytoDay Portions</li>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TuitionComponent