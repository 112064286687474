import React from 'react'
import one from '../../Images/one.jpg'
import us from '../../Images/us.jpg'
import { IoIosRocket } from "react-icons/io";
import { FaEye } from "react-icons/fa";


function AboutUs() {
    return (
        <div className='mt container'>
            <div className='d-flex justify-content-center'>
                <div className='row mt-5'>
                    <div className='col mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-0 feature_head mb-5'>Come Learn And Succeed With Us !</div>
                </div>
            </div>

            <div className='row'>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-5'>
                    <p className='about_us_cont ms-xxl-0 ms-xl-0 ms-lg-0 ms-md-0 ms-sm-3'>A leading educational institution offering German, IELTS, PSC, Bank Coaching, Spoken English, and Tuition services. Personalized instruction, experienced faculty, and student counseling ensure academic excellence and success.</p>
                </div>

                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                    <img src={us} className='img-fluid' />
                </div>
            </div>

            
        </div>
    )
}

export default AboutUs