import React, { useRef, useEffect } from 'react';
import banner from '../../Images/banner.jpg';
import call from '../../Images/call.jpg';
import post from '../../Images/post.jpg'


function GermanClass() {
  const textColumnRef = useRef(null);
  const imageColumnRef = useRef(null);



  return (
    <div className=''>
      <div className='row mb-5'>
        <div className='col mt-5'>
          <img src={banner} className='img-fluid banner_class' alt='Banner' />
        </div>
      </div>

      <div className='row text-center'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12'>
          <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
              <div class="card">
                <div className='card-title class_head'>
                  German for Beginners
                </div>
                <div class="card-body  class_para">
                  If you have plans to move to Germany for work or study, our German courses for beginners are the right place to start. We believe that when it comes to foreign language learning, one of the most integral components in achieving success is having a good start. If you have already mastered the basics of the language, graduating from the beginners level to the intermediate level will prove to be a natural process and you will reach your language goals in lesser time than others.

                </div>
              </div>
            </div>
          </div>


          <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
              <div class="card">
                <div className='card-title  class_head'>
                  What you learn in German Courses for beginners ?
                </div>
                <div class="card-body  class_para">
                  The prerequisite to successful language learning is the building of a solid foundation.With us this foundation comes in the form of picking up vocabulary,learning grammatical structures and combining the two.
                  In these courses for beginners,in the space of two months,you will master useful concepts that will help you start conversing with the Germans in most everyday situations.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-5'>
          <div class="card">
            <div className='card-title  class_head'>
              Super Intensive German Language Courses
            </div>
            <div class="card-body  class_para">
              <img src={post} className='img-fluid card-img-top ' alt='Call' />
              Our intensive German language courses are meant for people who want to learn German for work or study reasons but do not have lots of time on their hands.These courses let you immerse yourselves in German learning for a short period of time and still help you take your German skills to the next level.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GermanClass;
