import React from 'react'
import EnglishComponent from '../../Component/Classes/English.Component'
import Navbar from '../../Component/Landing/Navbar'
import ContactUs from '../../Component/Landing/ContactUs'

function MainEnglishClass() {
  return (
    <div>
        <Navbar/>
        <EnglishComponent/>
        <ContactUs/>
    </div>
  )
}

export default MainEnglishClass