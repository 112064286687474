import React from 'react'
import Navbar from '../../Component/Landing/Navbar'
import BenefitsComponent from '../../Component/InnerPages/Benefit.Component'
import ContactUs from '../../Component/Landing/ContactUs'

function MainBenefits() {
  return (
    <div>
        <Navbar/>
        <BenefitsComponent/>
        <ContactUs/>
    </div>
  )
}

export default MainBenefits