import React from 'react'
import banner from '../../Images/banner.jpg';
import course2 from '../../Images/course2.jpg'

function EnglishComponent() {
    return (
        <div>
            <div className='row mb-5'>
                <div className='col mt-5'>
                    <img src={banner} className='img-fluid banner_class' alt='Banner' />
                </div>
            </div>

            <div className='row text-center'>
                <div className='col '>
                    <p className='class_ielt'> IELTS - International English Language Testing System</p>

                    <p className='class_para'>
                        Stretch no further if you wanna score high in IELTS test as we have minified the four different IELTS modules to suit the varied learning styles of IELTS test takers. Take ielts effortlessly with the best ielts coaching centre in Ernakulam, Kerala. At Camford Academy, you can experience the mastery of how to approach each ielts module. Camford IELTS Training Center in Ernakulam reiterates its claim for the top ielts coaching class in Kerala, India with following features.
                    </p>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <div className='row text-center'>
                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                        <div className="card our-class-card mb-3">
                            <img src={course2} class="card-img-top img-fluid" alt="..." />
                            <div className="card-body ">
                                <h5 className=" card-title our-class-title ">Our Specialities </h5>
                                <li className="card-text our-class-text text-center p-2">Experienced Faculty</li>
                                <li className="card-text our-class-text text-center p-2">Online And Direct Classes</li>
                                <li className="card-text our-class-text text-center p-2">Doubt Clearnes test</li>
                                <li className="card-text our-class-text text-center p-2">Tnteractive Classes</li>
                                <li className="card-text our-class-text text-center p-2">Weekly Test</li>
                               
                            </div>
                        </div>
                    </div>

                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                        <div className="card our-class-card mb-3">
                            <img src={course2} class="card-img-top img-fluid" alt="..." />
                            <div className="card-body ">
                                <h5 className=" card-title our-class-title ">IELTS </h5>
                                <p className="card-text our-class-text text-center p-2">Preparations</p>
                                <p className="card-text our-class-text text-center p-2">Online Classes Available</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnglishComponent