import React from 'react'
import back from '../../Images/back.jpg'

function Review() {
    return (
        <div className='main_div_rev mb-5 text-center '>
            <div>
                <div className='row text-center'>
                    <div className='col review_head'>
                        What our Students Saying
                    </div>
                    <p className='sub_rev_cont'>
                        Read reviews from Google
                        </p>
                </div>

                <div className='row review_cont mt-4 ms-xxl-2 ms-xl-2 ms-lg-1 ms-md-1 ms-sm-1 me-xxl-2 me-xl-2 ms-lg-1 ms-md-1 ms-sm-1'>
                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0 revie_para '>
                        <p>I have cleared my German B1 level exam with the guidance of Arun Sir. He has a unique way of teaching where he identifies our strengths and weaknesses and helps the students to improve where he/she lags. I would recommend this class to anyone who is passionate about learning German.</p>

                        <p>--Arun</p>
                    </div>
                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0 revie_para'>
                        <p>Highly recommended!! I took my German B1 classes online with Arun Sir and have cleared the Goethe B1 exam in the first attempt. Way of teaching and exam preparation methods are highly satisfactory. I would definitely recommend the classes from Arun Sir for anyone willing to take German exams.</p>            
                        <p>--Anegha</p>
                        </div>
                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0 revie_para'>
                        <p>I was one of the students at Le Maple for a period of time. I was fully satisfied with the German language classes I was attending. There are only positive things to say about this coaching centre. No doubt this is the best in Trivandrum</p>            
                        <p>--Aswin</p>
                        </div>
                </div>

                <div className='row text-center'>
                    <div className='col '>
                        <button className='btn view-btn rounded-pill ps-4 pe-4 mb-2'>Read More</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Review