import React,{useEffect} from 'react'
import { BsTelephoneFill } from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import logo from '../../Images/logo.png'
import { useNavigate } from 'react-router-dom';
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

function ContactUs() {


  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const handlePhoneClick = () => {
    window.location.href = 'tel:+917558873777'; // Replace the phone number with your desired phone number
  };

  const handleEmailClick = () => {
    window.location.href = 'mailto:adensacademy@gmail.com'; // Replace the email address with your desired email address
  };

  const handleLocationClick = () => {
    window.location.href = 'https://www.google.com/maps/place/9%C2%B057\'14.5%22N+76%C2%B020\'42.1%22E/@9.9540537,76.3449988,21z/data=!4m4!3m3!8m2!3d9.9540358!4d76.3450165?hl=en&entry=ttu';
  };

  const handleWhatsappClick = () => {
    window.location.href = 'http://wa.me/+917558873777';
  };

  const handleInstagramClick = () => {
    window.location.href = 'https://www.instagram.com/adensacademy/?hl=en';
  };

  const handlefbClick = () => {
    window.location.href = 'https://www.facebook.com/adensacademy?mibextid=ZbWKwL';
  };


  return (
    <div className='contact'>
      <div className='main_contact_cont'>
        <div className='d-flex justify-content-center'>
          <div className='row mt-5'>
            <div className='col'>
              <img src={logo} className='contact_logo' />
              <p>ADENS ACADEMY</p>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          <div className='row '>
            <div className='col'>
              <FaWhatsapp style={{ color: "white" }} onClick={ handleWhatsappClick }/>
            
              <FaInstagram className='ms-3' onClick={handleInstagramClick} />
              <FaFacebook className='ms-3' onClick={ handlefbClick }/>



            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col ms-5'>
            <p>Best online platform for learning German </p>
          </div>
        </div>
        <div className='row ms-5 me-3'>
          <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 ms-xxl-0 ms-xl-0 ms-lg-0 mt-3'>
            <BsTelephoneFill className='' /> <span className='mt-5 class_point' onClick={handlePhoneClick}>+91 755 887 3777</span>

            <p className='mt-3 class_point' onClick={handleLocationClick}> <MdLocationPin />
              &nbsp;SN Junction, Near St Joseph School Metro Pillar No. 1033 Tripunithura</p>

            <p className='class_point' onClick={handleEmailClick}><MdEmail /> &nbsp;adensacademy@gmail.com
            </p>
          </div>

          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 mt-4 mb-2">
            <p>Quick Links</p>
            <li onClick={() => navigate("/aboutus")} className='class_point'>About</li>
            <li onClick={() => navigate("/ourClasses")} className='class_point'>Classes</li>
            <li onClick={() => navigate("/ourteam")} className='class_point'>Our team</li>
          </div>
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 mt-4 mb-2">
            <p>Our Courses</p>
            <li onClick={() => navigate("/germanclass")} className='class_point'>German Language</li>
            <li onClick={() => navigate("/adens/ielts")} className='class_point'>IELTS</li>
            <li onClick={() => navigate("/adens/psccoaching")} className='class_point'>PSC AND BANK</li>
            <li onClick={() => navigate("/adens/tuition")} className='class_point'>TUITION</li>
            <li onClick={() => navigate("/adens/spokenenglish")} className='class_point'>SPOKEN ENGLISH</li>
            <li>Counselling</li>
          </div>

        </div>

        <div className="row text-center">
          <div class=" d-flex justify-content-center">
            <div className="footer-copyright-bottom mb-5">
              © {new Date().getFullYear()} Team Adens Academy.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
