import React from 'react'
import Navbar from '../Landing/Navbar'
import ContactUs from '../Landing/ContactUs'
import banner from '../../Images/banner.jpg'
import one from '../../Images/one.jpg'
import { IoIosRocket } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import about from '../../Images/about.jpg'

function AboutComponent() {
    return (

        <div className='about_us'>
            <div className='row mb-5'>
                <div className='col mt-5'>
                    <img src={banner} className='img-fluid banner_class' />
                </div>
            </div>

            <div className='container'>
                <div className='row ms-1 mb-4'>

                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-5 mt-sm-5'>
                        <img src={about} className='img-fluid first_img me-5' />
                    </div>
                    <div className='col-xxl-5 col-xl-5 col-lg-6 col-md-4 col-sm-12 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-5 mt-sm-5 abt_comp me-3'>
                        <p className='cont_abt_comp '>A leading educational institution offering German, IELTS, PSC, Bank Coaching, Spoken English, and Tuition services. Personalized instruction, experienced faculty, and student counseling ensure academic excellence and success., a platform for language learners to get together and hone up their linguistic skills in a relaxed, stress-free and fun environment
                            Let the reason to learn a new language be your desire to live abroad, advancement of your career or pleasure, we have a course for you in English or German, no matter what your age is.
                            You can rest assured that our teachers provide you all the personal attention you need for the whole duration of the course. Last but not least, all our courses are reasonably priced and will fit your pocket</p>
                    </div>
                </div>
            </div>

            <div className='d-flex justify-content-center'>
                <div className='row text-center mb-4'>
                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-5 ms-xxl-0 ms-xl-0 ms-lg-0 ms-md-0 ms-sm mission_box text-center'>
                        <IoIosRocket className='rocket_class' />
                        <p className='mission_font'>Our Mission</p>
                        <p className='mission_sub'>Our mission is to provide training to fill the existing skills shortages in industry, along with issues such as the quality, reach and relevance to our education system& the economy.</p>
                    </div>


                    <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 ms-xxl-5 ms-xl-5 ms-lg-5 ms-md-1 ms-sm-1  mt-5 vision_box text-center'>
                        <FaEye />

                        <p className='mission_font'>Our Vission</p>
                        <p className='mission_sub'>Quality Education Worldwide</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutComponent