import React from 'react'
import Navbar from '../../Component/Landing/Navbar'
import OurTeam from '../../Component/InnerPages/OurTeam'
import ContactUs from '../../Component/Landing/ContactUs'

function MainTeam() {
  return (
    <div>
        <Navbar/>
        <OurTeam/>
        <ContactUs/>
    </div>
  )
}

export default MainTeam