import React from 'react'
import Navbar from './Navbar'
import AboutUs from './AboutUs'
import Features from './Features'
import Benefits from './Benefits'
import ContactUs from './ContactUs'
import Banner from './Banner'
import Review from './Review'
import FirstNav from './FirstNav'
import whatup from '../../Images/whatup.png'

function Landing() {

    const handleWhatsAppClick = () => {
        window.open('whatsapp://send?phone=7558873777', '_blank');
    };

    return (
        <div>
           
            <Navbar />
            <Banner/>
            <AboutUs />
            <Features />
            <Benefits />
            <Review/>
            <ContactUs/>
            <a href='http://wa.me/+917558873777'>
            <img src={whatup} className='what_up mb-5 me-sm-5 me-md-2 me-lg-4 me-xl-4 me-xxl-4'  style={{ cursor: 'pointer' }} />
            </a>
        </div>
    )
}

export default Landing