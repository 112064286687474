import React from 'react'
import welcome from '../../Images/welcome.jpg'

function Banner() {
  return (
    <div className='main'>
        <div className='row'>
            <div className='col mt-5'>
                <img className='img-fluid' src={welcome}/>
            </div>
        </div>
    </div>
  )
}

export default Banner