import React from 'react'
import logo from '../../Images/logo.png'
import { useNavigate } from 'react-router-dom'

function Navbar() {

  const navigate = useNavigate()
  return (
    <div className=''>
      <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top" style={{ top: 0, padding: 0, color: 'black', zIndex: 1000 }}>
        <div className="container-fluid main_nav_body">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo03"
            aria-controls="navbarTogglerDemo03"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <a className="navbar-brand" href="#">
            <img src={logo} className="img-fluid main_logo" />
          </a>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#">

                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={()=>navigate("/")}>
                  Home
                </a>
              </li>


              <li className="nav-item">
                <a className="nav-link" onClick={()=>navigate("/aboutus")}>
                  About Us
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" onClick={()=>navigate("/ourClasses")}>
                  Classes
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link" onClick={()=>navigate("/ourteam")} >
                  Our Team
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={()=>navigate("/contactus")} >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar