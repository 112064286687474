import React from 'react'
import one from '../../Images/one.jpg'

function Benefits() {
    return (
        <div className='mt-5 container mb-5'>
            <div className='row text-center'>
              
                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                        <img src={one} className='img-fluid' alt='Benefit' />
                    </div>

                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-3 mt-sm-5 mt-3 ms-xxl-0 ms-xl-0 ms-lg-0 ms-md-2 ms-sm-3 mission_box'>
                        <p className='mission_font'>Why Us ?</p>
                        <ul className='list-unstyled'>
                            <li>Experienced Instructor</li>
                            <li>Personalized Attention</li>
                            <li>Thorough Syllabus</li>
                            <li>Convenient Location</li>
                            <li>Affordable Rates</li>
                        </ul>
                    </div>
              
            </div>
        </div>
    )
}

export default Benefits