import React from 'react'
import wo1 from '../../Images/wo1.jpeg'
import wo2 from '../../Images/wo2.jpeg'
import wo3 from '../../Images/wo3.jpeg'
import md from '../../Images/md.jpeg'
import bed from '../../Images/bde.jpeg'
import bed1 from '../../Images/bed.jpeg'
import cont from '../../Images/cont.jpeg'
import edit from '../../Images/edit.jpeg'
import publi from '../../Images/publi.jpeg'


function OurTeam() {
    return (
        <div>
            <div className='mt-5 container'>

                <div className='row mt-5'>
                    <div className='col mt-5 feature_head'>Our Team</div>

                </div>

                <div className='row text-center'>
                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                        <div className="card our-class-card mb-3">
                            <img src={md} className="card-img-top img-fluid " alt="Bibin Thomas" />
                            <div className="card-body">
                                <h5 className="card-title our-class-title">Bibin Thomas</h5>
                                <p className="card-text our-class-text">Manging Director</p>
                            </div>
                        </div>
                    </div>

                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                        <div className="card our-class-card mb-3">
                            <img src={wo2} class="card-img-top team-img0" alt="..." />
                            <div className="card-body ">
                                <h5 className=" card-title our-class-title ">Lakshmi Taj</h5>
                                <p className="card-text our-class-text text-center p-2">Academic coordinator</p>
                            </div>
                        </div>
                    </div>

                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                        <div className="card our-class-card mb-3">
                            <img src={wo1} class="card-img-top img-fluid" alt="..." />
                            <div className="card-body ">
                                <h5 className=" card-title our-class-title ">Aswathy Anilkumar</h5>
                                <p className="card-text our-class-text text-center p-2">Academic coordinator</p>
                            </div>
                        </div>
                    </div>

                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                        <div className="card our-class-card mb-3">
                            <img src={publi} class="card-img-top team-img1" alt="..." />
                            <div className="card-body ">
                                <h5 className=" card-title our-class-title ">Sandra Shigi</h5>
                                <p className="card-text our-class-text text-center p-2">publication Coordinator</p>
                            </div>
                        </div>
                    </div>

                    
                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                        <div className="card our-class-card mb-3">
                            <img src={wo3} class="card-img-top team-img2" alt="..." />
                            <div className="card-body ">
                                <h5 className=" card-title our-class-title ">Merin Anoop </h5>
                                <p className="card-text our-class-text text-center p-2">Academic Advisor</p>
                            </div>
                        </div>
                    </div>

                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                        <div className="card our-class-card mb-3">
                            <img src={cont} class="card-img-top img-fluid" alt="..." />
                            <div className="card-body ">
                                <h5 className=" card-title our-class-title ">Joel Thomas</h5>
                                <p className="card-text our-class-text text-center p-2">Content Editor</p>
                            </div>
                        </div>
                    </div>

                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                        <div className="card our-class-card mb-3">
                            <img src={bed1} class="card-img-top team-img" alt="..." />
                            <div className="card-body ">
                                <h5 className=" card-title our-class-title ">Vinu Varghese</h5>
                                <p className="card-text our-class-text text-center p-2">Business Development Executive</p>
                            </div>
                        </div>
                    </div>

                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                        <div className="card our-class-card mb-3">
                            <img src={edit} class="card-img-top  team-img3" alt="..." />
                            <div className="card-body ">
                                <h5 className=" card-title our-class-title ">Joshy Thomas</h5>
                                <p className="card-text our-class-text text-center p-2">Content Editor</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                        <div className="card our-class-card mb-3">
                        <img src={bed} className="card-img-top team-img" alt="Bibin Thomas" />
                            <div className="card-body ">
                                <h5 className=" card-title our-class-title ">Nobin Paul </h5>
                                <p className="card-text our-class-text text-center p-2">Business Development Executive</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default OurTeam