import React from 'react'
import logo from '../../Images/logo.png'

function FirstNav() {
    return (
        <div className='mt-5'>
            <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top mt-5" style={{ top: 0, padding: 0, color: 'white', zIndex: 1000 }}>
                <div className='container mt-3 div_second_body'>
                    <div className='row text-center'>
                        <div className='col'>
                            <img className="img-fluid main_logo mt-3 mb-3" src={logo} />
                        </div>
                        <div className='col mt-3 mb-3'>
                            <p>Home</p>
                        </div>
                        <div className='col mt-3 mb-3'>
                            <p>Home</p>
                        </div>
                        <div className='col mt-3 mb-3'>
                            <p>Home</p>
                        </div>
                        <div className='col mt-3 mb-3'>
                            <p>Home</p>
                        </div>
                        <div className='col mt-3 mb-3'>
                            <p>Home</p>
                        </div>
                    </div>
                </div>

            </nav>
        </div>
    )
}

export default FirstNav