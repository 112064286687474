import React from 'react';
import banner from '../../Images/banner.jpg';
import call from '../../Images/call.jpg';
import callus from '../../Images/callus.jpg'
import { FaLocationArrow } from "react-icons/fa6";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

function ContactComponent() {
    return (
        <div>
            <div className='row '>
                <div className='col mt'>
                    <img src={banner} className='img-fluid banner_class' alt="Banner" />
                </div>
            </div>
            <div className='main_contact_div'>
                <div className='container'>
                    <div className='d-flex justify-content-center'>
                        <div className='row'>
                            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                                <img src={callus} className='img-fluid' alt="Call Us" />
                            </div>

                            <div className='col mt-3'>
                                <div className='row'>
                                    <div className='col'>
                                        <p className='contact_para'>Address</p>
                                        <FaLocationArrow /><span className='adre_para'> SN Junction, Near St Joseph School Metro Pillar No. 1033 Tripunithura</span>
                                    </div>
                                </div>

                                <div className='row mt-3'>
                                    <div className='col'>
                                        <p className='contact_para'>Phone Number</p>
                                        <BsTelephoneFill /> <span className='adre_para'>+91 755 887 3777</span>
                                    </div>
                                </div>

                                <div className='row mt-3'>
                                    <div className='col'>
                                        <p className='contact_para'>Email</p>
                                        <MdEmail /> <span className='adre_para'>adensacademy@gmail.com</span>
                                    </div>
                                </div>

                                <div className='row mt-3'>
                                    <div className='col'>
                                        <p className='adre_para'>#Success with Adens Academy!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mt-3'>
                    <div className='col'>
                        <p className='contact_para'>Google Map Location</p>
                        <iframe
                            title="Adens Academy Location"
                            width="100%"
                            height="300"
                            frameBorder="0"
                            style={{ border: 0 }}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3928.862868214935!2d76.34260311477434!3d9.954066192805798!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0873000cd68f7b%3A0xcc1e48761c2346a4!2sX83W%2BJ2G%2C%20Thrippunithura%2C%20Kochi%2C%20Ernakulam%2C%20Kerala%20682301!5e0!3m2!1sen!2sin!4v1647950463289!5m2!1sen!2sin"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactComponent;
