import React from 'react'
import banner from '../../Images/banner.jpg';
import course5 from '../../Images/course5.jpg'


function SpokenComponent() {
    return (
        <div>
            <div className='row mb-5'>
                <div className='col mt-5'>
                    <img src={banner} className='img-fluid banner_class' alt='Banner' />
                </div>
            </div>

            <div className='row text-center'>
                <div className='col '>
                    <p className='class_ielt'>Learn English online and improve your skills through our high-quality courses and resources.</p>

                    <p className='class_para'>
                        Practise your listening, reading, writing and speaking and learn useful language to use at work or to communicate effectively with friends                    </p>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
            <div className='row  text-center'>
                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                        <div className="card our-class-card mb-3">
                            <img src={course5} class="card-img-top img-fluid" alt="..." />
                            <div className="card-body ">
                                <h5 className=" card-title our-class-title ">Skills</h5>
                                <li className="card-text our-class-text text-center p-2">Audio and video to practise your listening and speaking skills. Online Classes Available</li>
                                <li className="card-text our-class-text text-center p-2">Model texts for all types of writing tasks.</li>
                                <li className="card-text our-class-text text-center p-2">Work on your reading skills to read more quickly and understand more.</li>


                            </div>
                        </div>
                    </div>


                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                        <div className="card our-class-card mb-3">
                            <img src={course5} class="card-img-top img-fluid" alt="..." />
                            <div className="card-body ">
                                <h5 className=" card-title our-class-title ">Grammer</h5>
                                <li className="card-text our-class-text text-center p-2">Clear and simple grammar explanations to help you revise and practise different grammar points.</li>
                                <li className="card-text our-class-text text-center p-2">Online exercises to help you check your understanding of the grammar points.</li>
                                <li className="card-text our-class-text text-center p-2">Use the grammar reference for further practice and more detailed explanations.</li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SpokenComponent