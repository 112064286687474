import React from 'react'

function BenefitsComponent() {
  return (
    <div>
         <div className='mt-5 container mb-5'>
            <div className='d-flex justify-content-center'>
                <div className='row mt-5'>
                    <div className='col mt-5 feature_head'>Benefits</div>
                </div>
            </div>
           
                <div className='row '>
                <div className='d-flex justify-content-center'>
                    <div className='col-7 mt-5 ms-xxl-0 ms-xl-0 ms-lg-0 ms-md-0 ms-sm-3 ms-3 mission_box text-center'>
                        <p className='mission_font'>Why Us ? </p>
                        <p className='mission_sub'><li>Experienced Instructor</li></p>
                        <p className='mission_sub'><li>Personlized Attention</li></p>
                        <p className='mission_sub'><li>Thorough Syllabus</li></p>
                        <p className='mission_sub'><li>Convenint Location</li></p>
                        <p className='mission_sub'><li>Affordable Rates</li></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BenefitsComponent