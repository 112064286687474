import React from 'react'
import { Routes, Route } from "react-router-dom";
import MainLanding from './Pages/MainLanding/MainLanding';
import MainAbout from './Pages/MainInnerPages/MainAbout';
import MainClasses from './Pages/MainInnerPages/MainClasses';
import MainBenefits from './Pages/MainInnerPages/MainBenefits';
import MainTeam from './Pages/MainInnerPages/MainTeam';
import MainContact from './Pages/MainInnerPages/MainContact';
import MainGermanClass from './Pages/Classes/MainGermanClass';
import MainEnglishClass from './Pages/Classes/MainEnglishClass';
import MainPsc from './Pages/Classes/MainPsc';
import MainTuition from './Pages/Classes/MainTuition';
import MainSpoken from './Pages/Classes/MainSpoken';

function Navigation() {
  return (
    <div>
        <Routes>
            <Route path="/" element={<MainLanding/>}/>
            <Route path="/aboutus" element={<MainAbout/>}/>
            <Route path="/ourClasses" element={<MainClasses/>}/>
            <Route path="/benefits" element={<MainBenefits/>}/>
            <Route path="/ourteam" element={<MainTeam/>}/>
            <Route path="/contactus" element={<MainContact/>}/>
            <Route path="/germanclass" element={<MainGermanClass/>}/>
            <Route path="/adens/ielts" element={<MainEnglishClass/>}/>
            <Route path="/adens/psccoaching" element={<MainPsc/>}/>
            <Route path='/adens/tuition' element={<MainTuition/>}/>
            <Route path="/adens/spokenenglish" element={<MainSpoken/>}/>
        </Routes>
    </div>
  )
}

export default Navigation