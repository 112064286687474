import React from 'react'
import Navbar from '../../Component/Landing/Navbar'
import TuitionComponent from '../../Component/Classes/Tuition.Component'
import ContactUs from '../../Component/Landing/ContactUs'

function MainTuition() {
  return (
    <div>
        <Navbar/>
        <TuitionComponent/>
        <ContactUs/>
    </div>
  )
}

export default MainTuition