import React from 'react'
import teacher1 from '../../Images/teacher 1.png'
import teacher2 from '../../Images/teacher2.png'
import teacher3 from '../../Images/teacher3.png'
import teacher4 from '../../Images/teacher4.png'
import teacher5 from '../../Images/teacher5.png'
import teacher6 from '../../Images/teacher6.png'
import course1 from '../../Images/course1.jpg'
import course2 from '../../Images/course2.jpg'
import course3 from '../../Images/course3.jpg'
import course4 from '../../Images/course4.jpg'
import course5 from '../../Images/course5.jpg'
import course6 from '../../Images/course6.jpg'
import { useNavigate } from 'react-router-dom'
import bed from '../../Images/bde.jpeg'

function ClassesComponent() {

    const navigate = useNavigate()

    return (
        <div className='mt-5 container'>

            <div className='row mt-5'>
                <div className='col mt-5 feature_head'>Our Classes</div>

            </div>

            <div className='row text-center'>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                    <div className="card our-class-card mb-3">
                        <img src={course1} class="card-img-top img-fluid" alt="..." />
                        <div className="card-body ">
                            <h5 className=" card-title our-class-title ">Learn German </h5>
                            <p className="card-text our-class-text text-center p-2">Language Courses</p>
                            <p className="card-text our-class-text text-center p-2">Online Classes Available</p>
                            <button className='btn view-btn rounded-pill ps-4 pe-4 mb-2' onClick={() => navigate("/germanclass")}>View</button>
                        </div>
                    </div>
                </div>

                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                    <div className="card our-class-card mb-3">
                        <img src={course2} class="card-img-top img-fluid" alt="..." />
                        <div className="card-body ">
                            <h5 className=" card-title our-class-title ">IELTS </h5>
                            <p className="card-text our-class-text text-center p-2">Preparations</p>
                            <p className="card-text our-class-text text-center p-2">Online Classes Available</p>
                            <button className='btn view-btn rounded-pill ps-4 pe-4 mb-2' onClick={() => navigate("/adens/ielts")}>View</button>
                        </div>
                    </div>
                </div>


                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                    <div className="card our-class-card mb-3">
                        <img src={course6} class="card-img-top img-fluid" alt="..." />
                        <div className="card-body ">
                            <h5 className=" card-title our-class-title ">PSC AND BANK</h5>
                            <p className="card-text our-class-text text-center p-2">Coaching</p>
                            <p className="card-text our-class-text text-center p-2">Online Classes Available</p>
                            <button className='btn view-btn rounded-pill ps-4 pe-4 mb-2' onClick={() => navigate("/adens/psccoaching")}>View</button>
                        </div>
                    </div>
                </div>

                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                    <div className="card our-class-card mb-3">
                        <img src={course3} class="card-img-top img-fluid" alt="..." />
                        <div className="card-body ">
                            <h5 className=" card-title our-class-title ">Tuition</h5>
                            <p className="card-text our-class-text text-center p-2">For Grades 8 to +2</p>
                            <p className="card-text our-class-text text-center p-2">Online Classes Available</p>
                            <button className='btn view-btn rounded-pill ps-4 pe-4 mb-2' onClick={() => navigate("/adens/tuition")}>View</button>
                        </div>
                    </div>
                </div>

                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                    <div className="card our-class-card mb-3">
                        <img src={course5} class="card-img-top img-fluid" alt="..." />
                        <div className="card-body ">
                            <h5 className=" card-title our-class-title ">Spoken English</h5>
                            <p className="card-text our-class-text text-center p-2">Classes</p>
                            <p className="card-text our-class-text text-center p-2">Online Classes Available</p>
                            <button className='btn view-btn rounded-pill ps-4 pe-4 mb-2' onClick={() => navigate("/adens/spokenenglish")}>View</button>
                        </div>
                    </div>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                    <div className="card our-class-card mb-3">
                        <img src={course4} class="card-img-top img-fluid" alt="..." />
                        <div className="card-body ">
                            <h5 className=" card-title our-class-title ">Counseling</h5>
                            <p className="card-text our-class-text text-center p-2">For Students</p>
                            <p className="card-text our-class-text text-center p-2">Direct Classes Available</p>
                            <button className='btn view-btn rounded-pill ps-4 pe-4 mb-2'>View</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClassesComponent