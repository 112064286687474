import React from 'react'
import GermanClass from '../../Component/Classes/GermanClass'
import Navbar from '../../Component/Landing/Navbar'
import ContactUs from '../../Component/Landing/ContactUs'

function MainGermanClass() {
  return (
    <div>
      <Navbar/>
      <GermanClass/>
      <ContactUs/>
    </div>
  )
}

export default MainGermanClass