import React from 'react'
import AboutComponent from '../../Component/InnerPages/About.Component'
import Navbar from '../../Component/Landing/Navbar'
import ContactUs from '../../Component/Landing/ContactUs'

function MainAbout() {
  return (
    <div>
        <Navbar/>
        <AboutComponent/>
        <ContactUs />
    </div>
  )
}

export default MainAbout